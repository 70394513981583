@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100;9..40,200;9..40,300;9..40,400;9..40,500;9..40,600;9..40,700;9..40,800;9..40,900;9..40,1000&display=swap');

.hide-scroll::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

.hide-scroll {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.MuiStepLabel-label{
  color: white !important;
}

/* hide slider scroll bar  */
#slider {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
#slider::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.pac-container{
  z-index: 1301;
  box-shadow: none;
  padding: 15px;
  border-top: 0px;
  border: solid 1px #8080804f;
  border-radius: 10px;
  margin-top: 10px;
}
